.color-code {
    @apply w-8 h-8 mr-2;
    &-black {
        @apply bg-black;
        background-image: url('/images/colors/black.jpg');
    }
    &-f_orange {
        background-color: #ea3f43;
        background-image: url('/images/colors/flourescent-orange.jpg');
    }
    &-navy {
        background-color: #54637b;
        background-image: url('/images/colors/navy.jpg');
    }
    &-jade {
        background-color: #8ef4e0;
        background-image: url('/images/colors/jade.jpg');
    }
    &-burgandy {
        background-color: #5d3134;
    }
    &-red {
        background-color: #c72a2e;
        background-image: url('/images/colors/red.jpg');
    }
    &-purple {
        background-color: #3012b2;
        background-image: url('/images/colors/purple.jpg');
    }
    &-royal {
        background-color: #1919af;
        background-image: url('/images/colors/royal.jpg');
    }
    &-grey_dark {
        background-color: #292f42;
        background-image: url('/images/colors/dark-grey.jpg');
    }
    &-teal {
        background-color: #6ebbda;
        background-image: url('/images/colors/teal.jpg');
    }
    &-yellow {
        background-color: #fbf519;
        background-image: url('/images/colors/yellow.jpg');
    }
    &-gold {
        background-color: #de8800;
        background-image: url('/images/colors/gold.jpg');
    }
    &-green_olive {
        background-color: #857f6b;
        background-image: url('/images/colors/olive.jpg');
    }
    &-brown {
        background-color: #403735;
        background-image: url('/images/colors/brown.jpg');
    }
    &-grey {
        background-color: #b0a99e;
    }
    &-beige {
        background-color: #c8bd8e;
    }
    &-green_forest {
        background-color: #0e3f11;
    }
}
