.box-wrap {
    @apply flex flex-col justify-between w-11/12 mb-6 p-8 bg-white text-lg;
    row-gap: 1.5em;
    @screen md {
        @apply w-128;
    }
    @screen lg {
        @apply w-12/25;
    }
}

.box-heading {
    @apply mt-0 mb-6 leading-none;
}
