.banner-hp {
    @apply bg-cover h-96;
    background-image: url('/images/multiuse-sleeve-lg.jpg');
    @screen lg {
        @apply bg-center h-128;
    }
}

.bg-main {
    background-image: url('/images/protective-sleeve-01.jpg');
}

.bg-hose {
    @apply hidden h-128 bg-contain bg-right-top bg-no-repeat;
    background-image: url('/images/hose-sample-02.png');
    @screen lg {
        @apply block w-1/2;
    }
}
