.w-content, .w-content-narrow, .w-content-copy {
    @apply max-w-11/12 mx-auto;
}
.w-content {
    @screen xl {
        @apply max-w-6xl;
    }
}
.w-content-narrow {
    @screen sm {
        @apply max-w-xl;
    }
    @screen lg {
        @apply max-w-4xl;
    }
    @screen xl {
       max-width: 59.5rem;
    }
}
.w-content-copy {
    @screen sm {
        @apply max-w-xl;
    }
    @screen xl {
        @apply max-w-xl;
    }
}

.link-box {
    @apply w-4/5 mx-auto mb-4 p-4 border border-gray-800 text-blue-800 transition-all duration-300 shadow-md bg-gray-100;
    @screen md {
        @apply w-56 ml-0 mr-4;
    }
    &:hover {
        @apply no-underline border-blue-600 shadow-lg bg-white;
    }
    h4 {
        @apply text-lg;
    }
}

.bg-d {
    background-image: url('/images/bkg-d.png');
}

.bg-g {
    @apply bg-gray-800;
    background-image: url('/images/bkg-g.png');
}

.section-dark {
    @apply bg-cover bg-center;
    background-image: url('/images/heavy-equipment.jpg');
    @screen xl {
        @apply bg-fixed;
    }
}
