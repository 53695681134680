.header-nav_item {
    @apply hidden order-1 mr-8;
    @screen md {
        @apply mr-12 text-lg;
    }
    @media (min-width: 35rem) {
        &:last-of-type {
            @apply mr-0;
        }
    }
    a {
        @apply text-blue-200;
        &:hover {
            @apply text-white no-underline;
        }
    }
}

$menu-items: (
    menu : 1,
    inquiries : 10,
    id : 20,
    design: 30,
    weather : 35,
    hose : 35,

);

@each $name, $mq-width in $menu-items {
    .header-nav_item-#{$name} {
        @include mq($mq-width * 1em) { display: inline-block; }
    }
}

.header-nav_item-menu {
    @apply block mb-0 mr-0 order-2;
    @media (min-width: 35rem) {
        @apply hidden;
    }
}

.header_mobile-open {
    ul {
        @apply block mt-6 text-lg;
    }
    a.header-menu {
        &:hover, &:focus, &:active {
            @apply no-underline text-white;
        }
    }
    .header-nav_item {
        @apply block mr-0 mb-0 border-b border-gray-600 text-center leading-normal;
        &:hover {
            @apply bg-blue-800;
        }
        a {
            @apply block w-full py-2;
        }
        &-less {
            @apply block pr-2 text-base text-right;
        }
        &-menu {
            @apply hidden;
        }
    }
    .header-nav_item-menu {
        @apply text-right;
    }
}
