.herobar {
    @apply py-8 bg-cover bg-right bg-no-repeat;
    background-image: url('/images/protective-sleeve-01-sm.jpg');
    @screen lg {
        background-image: url('/images/protective-sleeve-01.jpg');
    }
    @screen xl {
        @apply bg-center;
    }
}

.herobar-content {
    @apply py-4 ;
    @screen xl {
        @apply pb-2;
    }
}

.herobar-hp {
    @apply bg-white border-t-4 border-b-8 border-gray-900;
    @screen lg {
        @apply flex;
    }
}
.herobar_hp-img {
    @apply bg-cover bg-center h-96;
    background-image: url('/images/protective-sleeve-hp-sm.jpg');
    @screen lg {
        @apply w-3/5;
        background-image: url('/images/protective-sleeve-hp.jpg');
    }
}

.herobar-heading-wrap {
    @apply w-4/5 mx-auto my-8 p-16 bg-blue-800 text-lg text-white;
    @screen md {
        @apply w-3/5 mx-0 text-2xl;
    }
}

.herobar-heading {
    @apply text-3xl mt-0 mb-4 leading-none;
    @screen lg {
        @apply text-5xl;
    }
}

.herobar-subheading {
    @apply text-xl mt-2 mb-0 text-yellow-600 leading-normal;
    @screen lg {
        @apply text-3xl;
    }
}
