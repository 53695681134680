/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@import "common";

// global styles on the most common elements
html {
    @apply font-body leading-normal text-gray-800;
}

// common styles
@import "alerts",
    "content_alignment",
    "headings",
    "herobar",
    "forms",
    "modal",
    "media";

// public only styles
@import "content",
    "box",
    "buttons",
    "header",
    "colors";

// page specific
@import "p_index";

@tailwind utilities;
