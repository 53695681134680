// Leading resets tailwinds default line-height
button, .button {
    @apply py-3 px-4 text-white bg-blue-800 shadow-none border border-gray-200 rounded leading-tight font-bold;
    transition: all 0.35s ease-in-out;
    &:hover {
        @apply text-white bg-blue-900 border-blue-800 no-underline;
        transition: all 0.25s ease;
    }
    &-large {
        @apply py-5 px-6 text-lg;
        @screen lg {
            @apply text-xl;
        }
    }
    &-yellow {
        @apply text-gray-800 border-white bg-yellow-400;
        &:hover {
            @apply bg-yellow-500 text-gray-800 border-gray-800;
        }
    }
}

a.button {
    @apply inline-block;
}
